import { useEffect, useMemo, useState } from "react"
import ArticleListAccordion from "../ArticleListAccordion/ArticleListAccordion"
import ArticleItemAccordion from "../ArticleItemAccordion/ArticleItemAccordion"
import { TreeUpIcon } from "../../../icons/TreeUpIcon"
import { TreeDownIcon } from "../../../icons/TreeDownIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IArticle } from "../../../types/content"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import useWindowSize from "../../../hooks/useWindowSize"
import Accordion from "../../Assets/Accordion/Accordion"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { selectParams, setArticlesAsideList } from "../../../redux/slice/params"
import { useParams } from "react-router-dom"
import styles from "./ArticlesList.module.scss"

interface IExArticle extends IArticle {
  children: IExArticle[]
}

function insertDataByIdRecursive(arr: any, id: string, dataToInsert: IExArticle) {
  for (const item of arr) {
    if (item.id === id) {
      item.children.push(dataToInsert)
      return true
    } else if (item.children && item.children.length > 0) {
      const isInserted = insertDataByIdRecursive(item.children, id, dataToInsert)
      if (isInserted) return true
    }
  }
  return false
}

const deepFindNeedOption = (parentArr: IExArticle[], searchedId: string): IExArticle | null => {
  const needEl = parentArr.find((el) => el.id === searchedId)
  let isFind = false
  if (needEl) return needEl

  let result: IExArticle | null = null
  parentArr.forEach((ell) => {
    if (ell?.children?.length) {
      ell?.children?.forEach((elll: any) => {
        if (!isFind) {
          result = deepFindNeedOption(elll.children, searchedId)
          if (result) isFind = true
        }
      })
    }
  })
  return result
}

const ArticlesList = ({ articles }: { articles: IArticle[] }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const params = useParams()
  const { favoriteArticles } = useAppSelector(selectArticles)
  const { isArticlesAsideOpen, articlesAsideList } = useAppSelector(selectParams)
  const { isDesktop } = useWindowSize()
  const dispatch = useDispatch()

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const [isGlobalOpen, setGlobalOpen] = useState<boolean>(false)
  const [isGlobalOpenAllowed, setGlobalOpenAllowed] = useState<boolean>(false)

  useEffect(() => {
    const output: IExArticle[] = []
    articles.forEach((article) => {
      if (!article.parent_id) {
        output.push({ ...article, children: [] })
      } else {
        insertDataByIdRecursive(output, String(article.parent_id), {
          ...article,
          children: [],
        })
      }
    })
    dispatch(setArticlesAsideList(output))
  }, [articles])

  const articlesList = useMemo(() => {
    const recursiveInsertArticleContent = (article: any, isDefOpen: boolean) => {
      if (article?.children?.length) {
        return (
          <ArticleListAccordion
            key={article.id}
            name={article.text}
            href={article.id}
            isGlobalOpenAllowed={isGlobalOpenAllowed}
            isGlobalOpen={isGlobalOpen}
            isDefaultOpen={isDefOpen}
          >
            {article.children.map((i: any) => {
              return recursiveInsertArticleContent(i, isDefOpen)
            })}
          </ArticleListAccordion>
        )
      } else {
        return (
          <ArticleItemAccordion
            key={article.id}
            txt={article.text}
            href={article.id}
            isFavorite={Boolean(favoriteArticles?.find((i) => i.id === article.id))}
          />
        )
      }
    }

    return (
      <div className={styles.inner}>
        {articlesAsideList?.map((article, index) => {
          return (
            <ArticleListAccordion
              key={index}
              name={article.text}
              href={article.id}
              isGlobalOpenAllowed={isGlobalOpenAllowed}
              isGlobalOpen={isGlobalOpen}
              isChildren={article?.children?.length > 0}
              isDefaultOpen={isGlobalOpen}
            >
              {article?.children?.map((childArticle: any) => recursiveInsertArticleContent(childArticle, isGlobalOpen))}
            </ArticleListAccordion>
          )
        })}
      </div>
    )
  }, [articlesAsideList, favoriteArticles, isArticlesAsideOpen, params, isGlobalOpen, isGlobalOpenAllowed])

  const globalTrigger = () => {
    setGlobalOpenAllowed(true)
    setGlobalOpen((prev) => !prev)
    setTimeout(() => {
      setGlobalOpenAllowed(false)
    }, 200)
  }

  useEffect(() => {
    function setMax() {
      const windowHeight = window.innerHeight
      const headerHeight = (document.querySelector(".header") as HTMLDivElement)?.offsetHeight
      // высота экрана - высота хедера - отступы у aside сверху/снизу - высота кнопки Назад(на Главную) - хедер aside
      setMaxHeight(windowHeight - headerHeight - 50 - 72 - 44)
    }
    setMax()

    window.addEventListener("resize", setMax)
    return () => window.removeEventListener("resize", setMax)
  }, [])

  const ArticlesListContent = () => {
    return (
      <>
        {isDesktop && (
          <header className={styles.header}>
            <LocalizedLink to={"/articles"} className={styles.link}>
              {t(isDesktop ? "toMain" : "allArticles")}
            </LocalizedLink>
            <button onClick={globalTrigger} className={styles.btn}>
              {isArticlesAsideOpen ? <TreeUpIcon /> : <TreeDownIcon />}
            </button>
          </header>
        )}

        <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
          {articlesList}
        </ScrollBlock>
      </>
    )
  }

  return (
    <div className={styles.aside}>
      {isDesktop ? (
        <>
          {isDesktop && (
            <header className={styles.header}>
              <LocalizedLink to={"/articles"} className={styles.link}>
                {t(isDesktop ? "toMain" : "allArticles")}
              </LocalizedLink>
              <button onClick={globalTrigger} className={styles.btn}>
                {isArticlesAsideOpen ? <TreeUpIcon /> : <TreeDownIcon />}
              </button>
            </header>
          )}

          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
            {articlesList}
          </ScrollBlock>
        </>
      ) : (
        <Accordion header={t("allArticles")} className={styles["order-descr-accord"]} defaultOpen={false}>
          <ArticlesListContent />
        </Accordion>
      )}
    </div>
  )
}

export default ArticlesList
