import FavoriteBtn from "../../Assets/FavoriteBtn/FavoriteBtn"
import { useEffect, useState } from "react"
import { getApiUrl } from "../../../utils/getApiUrl"
import Avatar from "../../Assets/Avatar/Avatar"
import moment from "moment"
import Label from "../../Assets/Label/Label"
import { useAppSelector } from "../../../hooks"
import { selectAuth } from "../../../redux/slice/auth"
import { selectArticles, toggleFavoriteArticles } from "../../../redux/slice/articles"
import { useGetArticleQuery, useSetFavoriteArticleMutation } from "../../../redux/api/articles"
import { useDispatch } from "react-redux"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import TitleBack from "../../TitleBack/TitleBack"
import useWindowSize from "../../../hooks/useWindowSize"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import styles from "./Article.module.scss"

const Article = ({ id, isFavoriteLoading }: { id: string; isFavoriteLoading?: boolean }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const dispatch = useDispatch()
  const { user } = useAppSelector(selectAuth)
  const { favoriteArticles } = useAppSelector(selectArticles)
  const navigate = useNavigate()
  const { isDesktop } = useWindowSize()

  const { data: article, isLoading: isArticleLoading, isFetching, error } = useGetArticleQuery(id)
  const [setFav] = useSetFavoriteArticleMutation()

  useEffect(() => {
    setFavorite(Boolean(favoriteArticles?.find((i) => i.id === id)))
  }, [favoriteArticles, id])

  const [isFavorite, setFavorite] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setMobileMenuIsHidden(!user?.id))
  }, [id, user])

  useEffect(() => {
    if (error && error.data?.error === "No access") {
      navigate("/")
    }
  }, [error])

  const toggleFav = () => {
    if (!article) return
    try {
      setFavorite((prev) => !prev)
      setFav(id).then(() => {
        dispatch(toggleFavoriteArticles(article))
      })
    } catch (err) {
      console.error(err)
    }
  }

  return isArticleLoading || isFetching ? (
    <>
      <div className={clsx("skeletonBlock", styles.titleLoading)} />
      <div className={clsx("skeletonBlock", styles.creatorLoading)} />
      <div className={clsx("skeletonBlock", styles.textLoading)} />
    </>
  ) : article ? (
    <div>
      {user && user.id && !isDesktop && <TitleBack title={t("knowledgeBase")} className={styles.titleBack} />}

      <header className={styles.header}>
        <div className={styles.headerTop}>
          <h2 className={styles.title}>{article.name}</h2>
          {isDesktop && user && !isFavoriteLoading && id && (
            <FavoriteBtn isActive={isFavorite} onClick={toggleFav} isDesktop />
          )}
        </div>

        {user && (
          <div className={styles.headerBot}>
            <Avatar
              img={article.creator?.avatar_id ? getApiUrl() + "/web/user/avatar/" + article.creator.avatar_id : ""}
              name={article.name}
              size={"xs"}
              className={styles.avatar}
            />
            <p>{article.creator.name}</p>

            <span className={styles.dot} />

            <p className={styles.date}>{moment(article.created_at).locale(currentLang).format("DD MMMM")}</p>

            <span className={styles.dot} />

            <span>
              {article.view_count} {t("views")}
            </span>
          </div>
        )}
        {!isDesktop && user && !isFavoriteLoading && id && <FavoriteBtn isActive={isFavorite} onClick={toggleFav} />}
      </header>

      <div className={styles.article} dangerouslySetInnerHTML={{ __html: article.text }} />

      {article?.tags && article.tags.length > 0 ? (
        <div className={styles.artTags}>
          {article.tags.map((tag) => (
            <Label key={tag.id} txt={tag.name} mode={"gray"} />
          ))}
        </div>
      ) : null}
    </div>
  ) : null
}

export default Article
