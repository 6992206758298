import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IArticle } from "../../types/content"

type ParamsState = {
  isSearchVal: boolean
  isOrderWithoutRevCheck: boolean
  isArticlesAsideOpen: boolean | null
  articlesAsideList: IExArticle[]
}
interface IExArticle extends IArticle {
  children: IExArticle[]
}

const initialState: ParamsState = {
  isSearchVal: false,
  isOrderWithoutRevCheck: false,
  isArticlesAsideOpen: null,
  articlesAsideList: [],
}

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setIsSearchVal: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isSearchVal = action.payload
    },
    setOrderWithoutRevCheck: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isOrderWithoutRevCheck = action.payload
    },
    changeArticleAsideOpen: (state: ParamsState, action: PayloadAction<boolean | null>) => {
      state.isArticlesAsideOpen = action.payload
    },
    setArticlesAsideList: (state: ParamsState, action: PayloadAction<IExArticle[]>) => {
      state.articlesAsideList = action.payload
    },
  },
})

export const { setIsSearchVal, setOrderWithoutRevCheck, changeArticleAsideOpen, setArticlesAsideList } =
  paramsSlice.actions

export const selectParams = (state: any): ParamsState => state[paramsSlice.name]
